import { createRoot } from "react-dom/client";
import { StrictMode, lazy, Suspense } from "react";
import {Helmet} from "react-helmet"
import { kcContext } from "abesg/account/kcContext";

const theme = 'riskine-default-theme'
const KcLoginThemeApp = lazy(() => import(`./${theme}/login/KcApp`));
const KcAccountThemeApp = lazy(() => import(`./${theme}/account/KcApp`));
 

async function initialize() {
  const accountModule = await import(`./${theme}/account/kcContext`);
  const loginModule = await import(`./${theme}/login/kcContext`);
  const accountModuleContext = accountModule.kcContext;
  const loginModuleContext = loginModule.kcContext;  
  return {accountModuleContext,loginModuleContext}
}


initialize().then((s)=>{ createRoot(document.getElementById("root")!).render(

    
<StrictMode>
    <Suspense>
        {(() => {
            if ( s.loginModuleContext!== undefined) {
                return <KcLoginThemeApp kcContext={s.loginModuleContext} />;
            }
            if (s.accountModuleContext !== undefined) {
                return <KcAccountThemeApp kcContext={s.accountModuleContext} />;
            }
            throw new Error(
                "This app is a Keycloak theme" +
                "It isn't meant to be deployed outside of Keycloak"
            );

        })()}
    </Suspense>
</StrictMode>
);
})
